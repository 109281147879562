import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import HandledarutbildningImg from '../../images/utbildningar/handledarutbildning.jpg'
const Handledarutbildning = () => (
  <Layout>
    <Seo title='Handledarutbildning' />
    <PageHeader text='Handledarutbildning' />
    <div className='container'>
      <div className='row justify-content-around'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
          <div>

            <h2>Privat övningskörning</h2>
            <p>
              Från och med den 1 januari 2006 krävs det att den som ansöker om att bli godkänd som handledare för privat
              övningskörning med personbil har genomgått en handledarutbildning, även kallad
              introduktionsutbildning för privat övningskörning, behörighet B.
            </p>
          </div>

          <div>
            <h3>För att kunna bli handledare måste du:</h3>
            <ul>
              <li className='mb-0'>Fyllt 24 år</li>
              <li className='mb-0'>
                Ha ett giltigt körkort utfärdat i Sverige eller en annan EES-stat, för det fordon som ni ska övningsköra med,
                och ha haft det under sammanlagt minst fem av de senaste tio åren. Om du har ett körkort från en annan EES-stat måste du,
                tillsammans med ansökan, skicka med en kopia på det utländska körkortet.
              </li>
            </ul>
          </div>

          <div>
            <h3>Eleven:</h3>
            <ul>
              <li>Måste vara minst 15 år och 9 månader för att få gå introduktionsutbildningen.</li>
            </ul>
          </div>

          <div>
            <h3>Allmän kursinformation</h3>
            <p>
              Danderyds trafikskola är godkänd av transportstyrelsen som utbildare till "introduktionsutbildning för privat övningskörning,
              behörighet B". Vi följer transpertstyrelsens föreskrifter (VVFS 2005:76) och Transportstyrelsen är vår tillsynsmyndighet.
            </p>
          </div>

          <div>
            <h3>Kursplanens mål</h3>
            <p>Efter introduktionen skall blivande handledare och elev ha fått information om och känna till:</p>
            <ul>
              <li className='mb-0'>Körkortsutbildningens mål och innehåll samt regler för övningskörning</li>
              <li className='mb-0'>Planering och strukturering av övningskörningen</li>
              <li className='mb-0'>Viktiga faktorer för trafiksäkerhet och miljö</li>
            </ul>
          </div>

          <div>
            <h3>Utbildare</h3>
            <p>Per-Erik Gustafsson, Anki Lindström och Anna Wendelin som genomgått STR:s interna lärarkurs.</p>
          </div>

          <div>
            <h3>Bokning</h3>
            <p>
              Boka din kurs i god tid innan för att vara säker på att få ett datum som passar!
              Kom i god tid innan kursen börjar kl 18.00. För bokning ring Tel: 08-755 33 63 eller boka och betala din plats via e-handeln.
              Som inskriven kan du även boka din plats via elevcentralen
            </p>
          </div>

          <div>
            <h3>Efter avslutad kurs</h3>
            <p>
              Efter kursen rapporterar vi in att du genomfört utbildningen hos oss.
              Men glöm inte att du själv måste ansöka om handledarskap för privat övningskörning.
              Du måste göra en ansökan för varje elev, om du ska övningsköra med flera elever.
              Du kan som mest ha 5 godkännanden samtidigt.
            </p>
          </div>

        </div>

        {/* <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3'>
          <div className='card border-0'>
            <img className='card-img-top mb-0' src={require('../../images/handledarutbildning.jpg')} alt='handledarutbildning' />
            <div className='card-body text-center rounded-bottom themeColorTransparent'>
              <h4 className='card-title text-white'>Boka din kurs hos oss</h4>
              <a href='#1' className='btn btn-light'>Boka</a>
            </div>
          </div>
        </div> */}

        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3 text-center'>
          <img src={HandledarutbildningImg} alt='handledarutbildning' className='img-fluid rounded' />
          <p className='mb-1 text-sm-center'>
            {/* <Link to='/e-handel/productDetails/5470' className='btn btn-danger'>Boka din kurs hos oss</Link> */}
            <Link to='/boka-utbildning' className='btn btn-danger'>Boka din kurs hos oss</Link>
          </p>
        </div>

      </div>
    </div>
  </Layout>
)

export default Handledarutbildning
